import React from "react";
const classNames = {
    "S": "Стандарт",
    "SL": "Стандарт Леді",
    "SM": "Стандарт-мануал",
    "M": "Модифікований",
    "O": "Відкритий",
};
export default function ClassSetup(props) {
    const textAreaValue = props.classSetup.participants.join("\n");
    const parseParticipants = function (val) {
        return val.split("\n"); //.filter((p) => p.length > 0)
    };
    const taChangeHandler = function (e) {
        const participants = parseParticipants(e.target.value);
        props.onParticipantsChanged(Object.assign(Object.assign({}, props.classSetup), { participants: participants }));
    };
    const timesChangeHandler = function (e) {
        const newTimes = e.target.valueAsNumber;
        props.onParticipantsChanged(Object.assign(Object.assign({}, props.classSetup), { times: newTimes }));
    };
    const nonEmptyParticipants = props.classSetup.participants.filter((p) => p.length > 0);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "col" },
            React.createElement("h2", null,
                classNames[props.className],
                React.createElement("span", { className: "badge badge-primary bg-secondary mx-2" }, nonEmptyParticipants.length)),
            React.createElement("label", { htmlFor: "inputTimes" }, "\u041A\u0456\u043B\u044C\u043A\u0456\u0441\u0442\u044C \u043F\u043E\u0432\u0442\u043E\u0440\u0435\u043D\u044C"),
            React.createElement("input", { id: "inputTimes", type: "number", className: "form-control my-2", min: 1, max: 4, value: props.classSetup.times, onChange: timesChangeHandler }),
            React.createElement("textarea", { rows: 8, className: "form-control", value: textAreaValue, onChange: taChangeHandler }))));
}
