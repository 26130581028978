var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { API_ROOT } from "../../storage";
import { DateTime } from "luxon";
export function fetchMatches() {
    return __awaiter(this, void 0, void 0, function* () {
        const response = yield fetch(`${API_ROOT}/matches`, {
            method: "GET"
        });
        const parsedJSON = JSON.parse(yield response.text());
        const jsonWithDates = parsedJSON.map((item) => {
            var _a;
            return Object.assign(Object.assign({}, item), { created_at: (_a = DateTime.fromISO(item.created_at)) !== null && _a !== void 0 ? _a : null });
        });
        jsonWithDates.sort((a, b) => { return a.created_at < b.created_at; }).reverse();
        return jsonWithDates;
    });
}
export function deleteMatch(matchId) {
    return __awaiter(this, void 0, void 0, function* () {
        yield fetch(`${API_ROOT}/matches/${matchId}`, {
            method: "DELETE"
        });
    });
}
export function fetchMatchInProgress(matchId) {
    return __awaiter(this, void 0, void 0, function* () {
        const response = yield fetch(`${API_ROOT}/matches/${matchId}`);
        const matchInProgress = JSON.parse(yield response.text());
        const participantDictionary = getParticipantDictionary(matchInProgress.participants);
        for (const rng of Object.keys(matchInProgress.duels).map(Number)) {
            for (const duel of matchInProgress.duels[rng]) {
                duel.leftName = participantDictionary[duel.left].name;
                duel.rightName = participantDictionary[duel.right].name;
            }
        }
        matchInProgress.participantsDict = participantDictionary;
        return matchInProgress;
    });
}
export function fetchMatchOutcomes(matchId) {
    return __awaiter(this, void 0, void 0, function* () {
        const response = yield fetch(`${API_ROOT}/matches/${matchId}/outcomes`);
        return JSON.parse(yield response.text());
    });
}
export function fetchParticipantVictories(matchId) {
    return __awaiter(this, void 0, void 0, function* () {
        const response = yield fetch(`${API_ROOT}/matches/${matchId}/victories`);
        return JSON.parse(yield response.text());
    });
}
export function fetchMatchFromPracticarms(matchUrl) {
    return __awaiter(this, void 0, void 0, function* () {
        const response = yield fetch(`${API_ROOT}/duels/practicarms`, {
            method: "POST",
            body: JSON.stringify({ "url": matchUrl })
        });
        return JSON.parse(yield response.text());
    });
}
export function fetchMatchExcel(matchId) {
    return __awaiter(this, void 0, void 0, function* () {
        const response = yield fetch(`${API_ROOT}/matches/${matchId}/excel`);
        const responseBody = yield response.blob();
        const contentDisposition = response.headers.get("content-disposition");
        const filename = getFileName(contentDisposition);
        openFile(responseBody, filename);
    });
}
function getFileName(disposition) {
    const utf8FilenameRegex = /filename\*=UTF-8''([\w%\-\.]+)(?:; ?|$)/i;
    const asciiFilenameRegex = /^filename=(["']?)(.*?[^\\])\1(?:; ?|$)/i;
    let fileName = null;
    if (utf8FilenameRegex.test(disposition)) {
        fileName = decodeURIComponent(utf8FilenameRegex.exec(disposition)[1]);
    }
    else {
        // prevent ReDos attacks by anchoring the ascii regex to string start and
        //  slicing off everything before 'filename='
        const filenameStart = disposition.toLowerCase().indexOf('filename=');
        if (filenameStart >= 0) {
            const partialDisposition = disposition.slice(filenameStart);
            const matches = asciiFilenameRegex.exec(partialDisposition);
            if (matches != null && matches[2]) {
                fileName = matches[2];
            }
        }
    }
    return fileName;
}
export function openFile(responseBody, fileName) {
    const url = URL.createObjectURL(new Blob([responseBody]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
}
export function recordOutcome(matchId, outcome) {
    return __awaiter(this, void 0, void 0, function* () {
        yield fetch(`${API_ROOT}/matches/${matchId}/duels/${outcome.duel_id}/outcomes`, {
            method: "POST",
            body: JSON.stringify(outcome),
        });
    });
}
export function getMatchCompletion(match, outcomes, filter) {
    var _a, _b;
    let rangeDuels = [];
    if (filter === null || filter === void 0 ? void 0 : filter.range) {
        rangeDuels = (_a = match.duels[filter.range]) !== null && _a !== void 0 ? _a : [];
    }
    else {
        for (const range of Object.keys(match.duels).map(Number)) {
            rangeDuels.push(...((_b = match.duels[range]) !== null && _b !== void 0 ? _b : []));
        }
    }
    if (filter === null || filter === void 0 ? void 0 : filter.clazz) {
        rangeDuels = rangeDuels.filter((d) => {
            return d.clazz == filter.clazz;
        });
    }
    return getCompletionRate(rangeDuels, outcomes);
}
export function getRangeCompletion(match, outcomes, range) {
    var _a;
    const duels = (_a = match.duels[range]) !== null && _a !== void 0 ? _a : [];
    return getCompletionRate(duels, outcomes);
}
function getCompletionRate(duels, outcomes) {
    const duelIDs = duels.map((d) => { return d.id; });
    const totalDuels = duels.length;
    const mostRecentOutcomes = getMostRecentOutcomes(outcomes);
    const completedDuels = Object.keys(mostRecentOutcomes).filter((duelId) => {
        return duelIDs.includes(duelId);
    }).length;
    return {
        completed: completedDuels,
        total: totalDuels
    };
}
function getParticipantDictionary(participants) {
    return participants.reduce((obj, participant) => {
        obj[participant.id] = participant;
        return obj;
    }, {});
}
export function getMostRecentOutcomes(outcomes) {
    const mostRecentOutcomes = {};
    for (const duelId of Object.keys(outcomes.outcomes)) {
        const o = outcomes.outcomes[duelId];
        const last = o.reduce((max, obj) => max.created_at > obj.created_at ? max : obj);
        mostRecentOutcomes[duelId] = last;
    }
    return mostRecentOutcomes;
}
