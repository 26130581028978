var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useState } from "react";
import { CLASSES, EmptyMatchSetupRequest } from "../models";
function convertClass(classVerbose, category) {
    const mapping = {
        "Стандарт": "S",
        "Стандарт-мануал": "SM",
        "Модифікований": "M",
        "Відкритий": "O"
    };
    const clazz = mapping[classVerbose];
    if (clazz == "S" && category == "Леді")
        return "SL";
    console.log(classVerbose, category);
    return clazz;
}
function getParticipant(chunk) {
    const name = chunk[1];
    const clazzVerbose = chunk[chunk.length - 1];
    const category = chunk.length == 6 ? chunk[chunk.length - 2] : "Загальна";
    const clazz = convertClass(clazzVerbose, category);
    return { name: name, clazz: clazz };
}
function getMatchSetup(taValue) {
    let result = EmptyMatchSetupRequest();
    const rows = taValue.trim().split("\n");
    const re = new RegExp("[0-9]{1,2}\\.");
    let indices = rows.map((val, idx, _) => {
        if (re.test(val)) {
            return idx;
        }
    }).filter((val) => {
        return val != null;
    });
    indices.push(rows.length);
    let indexPairs = [];
    for (let idx = 0; idx < indices.length - 1; idx++) {
        indexPairs.push([indices[idx], indices[idx + 1]]);
    }
    let chunks = [];
    for (const [first, last] of indexPairs) {
        chunks.push(rows.slice(first, last));
    }
    const participants = chunks.map(getParticipant);
    console.log(participants);
    const classList = CLASSES.map((clazz) => {
        const classParticipants = participants
            .filter((p) => (p.clazz == clazz))
            .map((p) => (p.name));
        return {
            "clazz": clazz,
            "participants": classParticipants,
            "times": 1,
        };
    });
    result.ranges["1"].classes = Object
        .fromEntries(classList.map((_a) => {
        var { clazz } = _a, rest = __rest(_a, ["clazz"]);
        return ([clazz, rest]);
    }));
    return result;
}
export default function MatchLoader(props) {
    const [taValue, setTaValue] = useState("");
    const handleTextAreaValueChanged = function (e) {
        const newValue = e.target.value;
        setTaValue(newValue);
    };
    const handleSaveMatchClicked = () => {
        const matchSetup = getMatchSetup(taValue);
        props.onMatchLoaded(matchSetup);
    };
    if (!props.visible) {
        return React.createElement(React.Fragment, null);
    }
    return React.createElement("div", { className: "card" },
        React.createElement("div", { className: "card-body" },
            React.createElement("h2", { className: "card-title" }, "\u0417\u0430\u0432\u0430\u043D\u0442\u0430\u0436\u0435\u043D\u043D\u044F \u0441\u043F\u0438\u0441\u043A\u0443 \u0437 practicarms"),
            React.createElement("label", { htmlFor: "textareaPracticarmsList" },
                "\u0421\u043A\u043E\u043F\u0456\u044E\u0439\u0442\u0435 \u0443\u0441\u044E \u0442\u0430\u0431\u043B\u0438\u0446\u044E \u0443\u0447\u0430\u0441\u043D\u0438\u043A\u0456\u0432 \u0437 practicarms \u0443 \u043F\u043E\u043B\u0435 \u0432\u0432\u043E\u0434\u0443 \u043D\u0438\u0436\u0447\u0435. ",
                React.createElement("br", null),
                "\u041F\u0435\u0440\u0435\u043A\u043E\u043D\u0430\u0439\u0442\u0435\u0441\u044C, \u0449\u043E \u0432\u0438 \u0441\u043A\u043E\u043F\u0456\u044E\u0432\u0430\u043B\u0438 \u0443\u0441\u0456 \u043F\u043E\u043B\u044F \u0432 \u0443\u0441\u0456\u0445 \u0440\u044F\u0434\u043A\u0430\u0445 (\u0432\u043A\u043B\u044E\u0447\u043D\u043E \u0437 \u043D\u043E\u043C\u0435\u0440\u043E\u043C, \u043A\u043B\u0430\u0441\u043E\u043C, \u043A\u0430\u0442\u0435\u0433\u043E\u0440\u0456\u0454\u044E, ...). ",
                React.createElement("br", null),
                "\u041A\u043E\u043F\u0456\u044E\u0432\u0430\u0442\u0438 \u0437\u0430\u0433\u043E\u043B\u043E\u0432\u043E\u043A \u0442\u0430\u0431\u043B\u0438\u0446\u0456 \u043D\u0435 \u043F\u043E\u0442\u0440\u0456\u0431\u043D\u043E."),
            React.createElement("textarea", { className: "form-control", rows: 20, id: "textareaPracticarmsList", value: taValue, onChange: handleTextAreaValueChanged }),
            React.createElement("button", { type: "button", className: "btn btn-primary", onClick: handleSaveMatchClicked }, "\u2B07\uFE0F\u0417\u0431\u0435\u0440\u0435\u0433\u0442\u0438"),
            React.createElement("button", { type: "button", className: "btn btn-secondary", onClick: props.onCanceled }, "\u0417\u0430\u043A\u0440\u0438\u0442\u0438")));
}
