import React from "react";
import { Badge, Spinner } from "react-bootstrap";
export default function ProgressCounter(props) {
    if (props.status == null)
        return React.createElement(Spinner, null);
    const isCompleted = props.status.completed == props.status.total;
    const bg = isCompleted ? 'success' : 'secondary';
    return React.createElement(Badge, { bg: bg },
        props.status.completed,
        "/",
        props.status.total);
}
