import React from "react";
import { CLASSES, RANGES } from "../models";
import ClassSetup from "./class_setup";
export default function MatchSetup(props) {
    const participantsChangedHandler = function (range, clazz, c) {
        const newMatchSetup = {
            ranges: Object.assign(Object.assign({}, props.matchSetup.ranges), { [range]: {
                    classes: Object.assign(Object.assign({}, props.matchSetup.ranges[range].classes), { [clazz]: c })
                } })
        };
        console.log("Participant list changed:");
        console.log(newMatchSetup);
        props.onMatchSetup(newMatchSetup);
    };
    const ranges = [];
    for (const range of RANGES) {
        const r = props.matchSetup.ranges[range];
        const defaultSetup = { participants: [], times: 1 };
        const classes = r ? r.classes : {};
        const classControls = [];
        let participantCount = 0;
        for (const clazz of CLASSES) {
            const setup = classes[clazz] || defaultSetup;
            participantCount += setup.participants.filter((p) => (p.length > 0)).length;
            classControls.push(React.createElement("div", { className: "col", key: `${range}-${clazz}` },
                React.createElement(ClassSetup, { className: clazz, classSetup: setup, onParticipantsChanged: (c) => participantsChangedHandler(range, clazz, c) })));
        }
        ranges.push(React.createElement("div", { className: "row py-3", key: range },
            React.createElement("h1", null,
                "\u0420\u0443\u0431\u0456\u0436 ",
                range,
                React.createElement("span", { className: "badge badge-primary bg-secondary mx-2" }, participantCount)),
            classControls));
    }
    return (React.createElement(React.Fragment, null, ranges));
}
