var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from "react";
import { Link, useLoaderData } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import { Badge, Button, Modal, Stack, Table } from "react-bootstrap";
import { FaArrowLeft, FaDownload } from "react-icons/fa";
import { fetchMatchExcel, fetchParticipantVictories, getMatchCompletion } from "./match_service";
import { CLASSES } from "../../models";
import ProgressCounter from "./progress_counter";
export function loader({ params }) {
    return __awaiter(this, void 0, void 0, function* () {
        return React.createElement(React.Fragment, null,
            React.createElement(MatchResults, { matchId: params.matchId }));
    });
}
export const MatchResultsComponent = () => useLoaderData();
export function MatchResults(params) {
    return (React.createElement(Navbar, null,
        React.createElement(Container, null,
            React.createElement(Link, { to: `/matches/${params.matchId}` },
                React.createElement(Button, null,
                    React.createElement(FaArrowLeft, null),
                    "\u00A0\u041D\u0430\u0437\u0430\u0434 \u0434\u043E \u043C\u0430\u0442\u0447\u0443")))));
}
export function MatchResultsModal(params) {
    var _a, _b;
    const defaultVictories = () => { return {}; };
    const [victories, setVictories] = useState(defaultVictories());
    useEffect(() => {
        (() => __awaiter(this, void 0, void 0, function* () {
            if (params.match.id == "") {
                return;
            }
            const fetched = yield fetchParticipantVictories(params.match.id);
            let result = {};
            for (const v of fetched) {
                result[v.participant_id] = v;
            }
            setVictories(result);
        }))();
    }, [params.outcomes]);
    const RANGES = Object.keys(params.match.duels).map(Number);
    // results by class + range
    let results = {};
    for (const clazz of CLASSES) {
        let classResultsByRange = {};
        for (const rng of RANGES) {
            classResultsByRange[rng] = [];
            const participantIDs = params.match.participants_by_range[rng].filter((p) => { return params.match.participantsDict[p].clazz == clazz; });
            for (const participantID of participantIDs) {
                const victory = victories[participantID];
                const participant = params.match.participantsDict[participantID];
                classResultsByRange[rng].push({
                    dq: (_a = victory === null || victory === void 0 ? void 0 : victory.dq) !== null && _a !== void 0 ? _a : false,
                    participant: participant,
                    participant_id: participantID,
                    victories: (_b = victory === null || victory === void 0 ? void 0 : victory.victories) !== null && _b !== void 0 ? _b : 0,
                });
            }
        }
        results[clazz] = classResultsByRange;
    }
    const victoriesRows = [];
    for (const clazz of CLASSES) {
        for (const range of RANGES) {
            let classRangeVictories = results[clazz][range];
            if (classRangeVictories.length == 0) {
                continue;
            }
            const status = getMatchCompletion(params.match, params.outcomes, { range: range, clazz: clazz });
            victoriesRows.push(React.createElement(RangeClassResults, { key: clazz + range, clazz: clazz, range: range, victories: classRangeVictories, status: status }));
        }
    }
    const handleExcelDownload = () => __awaiter(this, void 0, void 0, function* () {
        yield fetchMatchExcel(params.match.id);
    });
    return (React.createElement(Modal, { show: params.show, onHide: params.onHide },
        React.createElement(Modal.Header, { closeButton: true },
            React.createElement(Modal.Title, null,
                "\u0420\u0435\u0437\u0443\u043B\u044C\u0442\u0430\u0442\u0438 \u043C\u0430\u0442\u0447\u0443 \"",
                params.match.name,
                "\"")),
        React.createElement(Modal.Body, null,
            React.createElement(Stack, { gap: 2 },
                React.createElement(Button, { onClick: handleExcelDownload },
                    React.createElement(FaDownload, null),
                    " \u0421\u043A\u0430\u0447\u0430\u0442\u0438 \u0432\u0456\u0434\u043E\u043C\u0456\u0441\u0442\u044C"),
                victoriesRows))));
}
function RangeClassResults(params) {
    params.victories.sort((a, b) => {
        if (a.victories < b.victories)
            return 1;
        if (a.victories > b.victories)
            return -1;
        return 0;
    });
    let sortedVictoryCounts = [...(new Set(params.victories.map(v => v.victories)))].sort((a, b) => b - a);
    const getWinnerCount = (victoryCounts) => {
        return params.victories.filter(v => victoryCounts.includes(v.victories)).length;
    };
    const topVictoryCounts = sortedVictoryCounts.reduce((obj, next) => {
        if (getWinnerCount(obj) < 4) {
            obj.push(next);
        }
        return obj;
    }, []);
    const isCompleted = params.status.completed == params.status.total;
    const isGroupExitNeeded = getWinnerCount(topVictoryCounts) > 4;
    const lastVictoryCount = topVictoryCounts[topVictoryCounts.length - 1];
    const rows = params.victories.map((v) => {
        const isWinner = topVictoryCounts.includes(v.victories);
        let badge = React.createElement(React.Fragment, null);
        if (v.dq) {
            badge = React.createElement(Badge, { bg: "danger" }, "dq");
        }
        else if (isCompleted) {
            if (isWinner) {
                if (isGroupExitNeeded && v.victories == lastVictoryCount) {
                    badge = React.createElement(Badge, { bg: "warning" }, "\u0412\u0438\u0445\u0456\u0434 \u0437 \u0433\u0440\u0443\u043F\u0438");
                }
                else {
                    badge = React.createElement(Badge, { bg: "success" }, "\u041F\u0456\u0432\u0444\u0456\u043D\u0430\u043B");
                }
            }
        }
        return (React.createElement("tr", { key: v.participant.id },
            React.createElement("td", null,
                v.participant.name,
                " ",
                badge),
            React.createElement("td", null, v.victories)));
    });
    return (React.createElement(React.Fragment, null,
        React.createElement("h2", null,
            params.clazz,
            " - \u0440\u0443\u0431\u0456\u0436 \u2116",
            params.range,
            " ",
            React.createElement(ProgressCounter, { status: params.status })),
        React.createElement(Table, null,
            React.createElement("thead", null,
                React.createElement("tr", null,
                    React.createElement("th", null, "\u0423\u0447\u0430\u0441\u043D\u0438\u043A"),
                    React.createElement("th", null, "\u041F\u0435\u0440\u0435\u043C\u043E\u0433"))),
            React.createElement("tbody", null, rows))));
}
