var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import { Button, Col, Modal, Row, Stack } from "react-bootstrap";
import { deleteMatch, fetchMatches, fetchMatchOutcomes, getMatchCompletion } from "./match_service";
import { FaTrash } from "react-icons/fa";
import { Link } from "react-router-dom";
import ProgressCounter from "./progress_counter";
import { DateTime } from "luxon";
export default function MatchList() {
    const emptyMatches = [];
    const [matches, setMatches] = useState(emptyMatches);
    useEffect(() => {
        (() => __awaiter(this, void 0, void 0, function* () {
            const matches = yield fetchMatches();
            setMatches(matches);
        }))();
    }, []);
    const emptyStatuses = {};
    const [statuses, setStatuses] = useState(emptyStatuses);
    useEffect(() => {
        (() => __awaiter(this, void 0, void 0, function* () {
            for (const match of matches) {
                const fetchedOutcomes = yield fetchMatchOutcomes(match.id);
                statuses[match.id] = getMatchCompletion(match, fetchedOutcomes);
                setStatuses(Object.assign({}, statuses));
            }
        }))();
    }, [matches]);
    const defaultDeleteMatchModalProps = {
        show: false,
        onClose: null,
        match: null,
    };
    const [deleteMatchModalProps, setDeleteMatchModalprops] = useState(defaultDeleteMatchModalProps);
    const handleMatchDeleteClose = (matchId, deleteConfirmed) => __awaiter(this, void 0, void 0, function* () {
        if (deleteConfirmed) {
            yield deleteMatch(matchId);
            const matches = yield fetchMatches();
            setMatches(matches);
        }
        setDeleteMatchModalprops(Object.assign(Object.assign({}, deleteMatchModalProps), { show: false }));
    });
    const buildMatchDeleteClickHandler = (matchId) => {
        return (e) => {
            const match = matches.filter(m => m.id == matchId)[0];
            setDeleteMatchModalprops(Object.assign(Object.assign({}, deleteMatchModalProps), { match: match, show: true }));
        };
    };
    const rows = [];
    for (const match of matches) {
        const createdAt = match.created_at.toLocaleString(DateTime.DATETIME_SHORT, { locale: "uk-UA" });
        rows.push(React.createElement(Container, { key: match.id, fluid: true },
            React.createElement(Row, null,
                React.createElement(Col, null,
                    React.createElement("h2", null, match.name))),
            React.createElement(Row, null,
                React.createElement("span", null,
                    "\u0421\u0442\u0432\u043E\u0440\u0435\u043D\u043E ",
                    createdAt,
                    ".")),
            React.createElement(Row, { className: "mt-3" },
                React.createElement(Col, { className: "d-flex justify-content-between gap-3" },
                    React.createElement(Link, { to: `/matches/${match.id}` },
                        React.createElement(Button, { size: "lg" },
                            "\u0421\u0443\u0434\u0438\u0442\u0438 \u00A0",
                            React.createElement(ProgressCounter, { status: statuses[match.id] }))),
                    React.createElement(Button, { variant: "outline-danger", size: "sm", onClick: buildMatchDeleteClickHandler(match.id) },
                        "\u0410\u0440\u0445\u0456\u0432\u0443\u0432\u0430\u0442\u0438\u00A0",
                        React.createElement(FaTrash, null)))),
            React.createElement("hr", null)));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Container, null,
            React.createElement("h1", null, "\u041C\u0430\u0442\u0447\u0456"),
            React.createElement("hr", null),
            React.createElement(Stack, { gap: 3 }, rows)),
        React.createElement(DeleteMatchModal, { match: deleteMatchModalProps.match, show: deleteMatchModalProps.show, onClose: handleMatchDeleteClose })));
}
function DeleteMatchModal(props) {
    if (props.match == null) {
        return React.createElement(React.Fragment, null);
    }
    return (React.createElement(Modal, { show: props.show, onHide: () => props.onClose(props.match.id, false) },
        React.createElement(Modal.Header, { closeButton: true },
            React.createElement(Modal.Title, null,
                "\u0410\u0440\u0445\u0456\u0432\u0443\u0432\u0430\u0442\u0438 \u043C\u0430\u0442\u0447 ",
                props.match.name,
                "?")),
        React.createElement(Modal.Footer, null,
            React.createElement(Container, { className: "d-flex justify-content-between" },
                React.createElement(Button, { onClick: event => props.onClose(props.match.id, false), variant: "outline-dark" },
                    React.createElement(FaTrash, null),
                    " \u0417\u0430\u043A\u0440\u0438\u0442\u0438"),
                React.createElement(Button, { onClick: event => props.onClose(props.match.id, true), variant: "danger" },
                    React.createElement(FaTrash, null),
                    " \u0410\u0440\u0445\u0456\u0432\u0443\u0432\u0430\u0442\u0438")))));
}
