import React from "react";
import { RANGES } from "../models";
export default function DuelsList(props) {
    if (props.isLoading) {
        return React.createElement("h2", null, "Loading...");
    }
    if (!props.match) {
        return React.createElement(React.Fragment, null);
    }
    if (!props.match.ranges) {
        return React.createElement(React.Fragment, null);
    }
    const ranges = [];
    for (const range of RANGES) {
        const duels = props.match.ranges[range] || [];
        const rows = [];
        let orderNum = 1;
        for (const duel of duels) {
            rows.push(React.createElement("tr", { key: orderNum + duel.left.name + duel.right.name },
                React.createElement("td", null, duel.left.name),
                React.createElement("td", null, duel.right.name),
                React.createElement("td", null, duel.clazz)));
            orderNum += 1;
        }
        const tbody = React.createElement("tbody", null, rows);
        const tbl = (React.createElement("div", { className: "col", key: range },
            React.createElement("h2", null,
                "\u0420\u0443\u0431\u0456\u0436 ",
                range,
                " ",
                React.createElement("span", { className: "badge bg-secondary" }, duels.length),
                " "),
            React.createElement("table", { className: "table" },
                React.createElement("thead", null,
                    React.createElement("tr", null,
                        React.createElement("th", { scope: "col" }, "\u0417\u043B\u0456\u0432\u0430"),
                        React.createElement("th", { scope: "col" }, "\u0421\u043F\u0440\u0430\u0432\u0430"),
                        React.createElement("th", { scope: "col" }, "\u041A\u043B\u0430\u0441"))),
                tbody)));
        ranges.push(tbl);
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "row" }, ranges)));
}
