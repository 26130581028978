import React from "react";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { NavLink, Outlet } from 'react-router-dom';
export function RootNavbar() {
    return (React.createElement(Navbar, { className: "bg-body-secondary mb-4" },
        React.createElement(Container, null,
            React.createElement(Navbar.Brand, null,
                React.createElement("h1", null, "\u0414\u0443\u0435\u043B\u0456")),
            React.createElement(Navbar.Toggle, { "aria-controls": "basic-navbar-nav" }),
            React.createElement(Navbar.Collapse, { id: "basic-navbar-nav" },
                React.createElement(Nav, { variant: "underline", className: "me-auto" },
                    React.createElement(Nav.Item, null,
                        React.createElement(Nav.Link, { as: NavLink, to: "/duels" }, "\u041F\u043B\u0430\u043D\u0443\u0432\u0430\u043D\u043D\u044F")),
                    React.createElement(Nav.Item, null,
                        React.createElement(Nav.Link, { as: NavLink, to: "/matches" }, "\u0421\u0443\u0434\u0434\u0456\u0432\u0441\u0442\u0432\u043E")))))));
}
export function RootComponent() {
    return (React.createElement(React.Fragment, null,
        React.createElement(RootNavbar, null),
        React.createElement(Outlet, null)));
}
