var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from "react";
import { useLoaderData } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import { Button, ButtonGroup, Col, Row, ToggleButton } from "react-bootstrap";
import DuelList from "./duel_list";
import { fetchMatchInProgress, fetchMatchOutcomes, getMatchCompletion } from "./match_service";
import ProgressCounter from "./progress_counter";
import { MatchResultsModal } from "./match_results";
export function loader({ params }) {
    return __awaiter(this, void 0, void 0, function* () {
        return React.createElement(React.Fragment, null,
            React.createElement(MatchReferee, { matchId: params.matchId }));
    });
}
export const MatchOutcomesComponent = () => useLoaderData();
export function MatchReferee(params) {
    var _a, _b;
    const defaultMatch = () => {
        return {
            created_at: undefined,
            duels: {},
            id: "",
            name: "",
            participants: [],
            participants_by_range: {},
            participantsDict: {}
        };
    };
    const [match, setMatch] = useState(defaultMatch());
    // state for fetching match outcomes, displays the match or something.
    const [outcomesStale, setOutcomesStale] = useState(false);
    const defaultOutcomes = () => {
        return { outcomes: {} };
    };
    const [outcomes, setOutcomes] = useState(defaultOutcomes());
    useEffect(() => {
        (() => __awaiter(this, void 0, void 0, function* () {
            setMatch(yield fetchMatchInProgress(params.matchId));
            setOutcomes(yield fetchMatchOutcomes(params.matchId));
            setOutcomesStale(false);
        }))();
    }, [outcomesStale]);
    const handleOutcomeRecorded = () => __awaiter(this, void 0, void 0, function* () {
        setOutcomesStale(true);
        // setOutcomes(await fetchMatchOutcomes(params.matchId))
    });
    const ranges = Object.keys((_a = match.duels) !== null && _a !== void 0 ? _a : {}).map(Number).filter(rng => match.duels[rng].length > 0);
    const [selectedRange, setSelectedRange] = useState(1);
    const rangeButtons = [];
    for (const r of ranges) {
        rangeButtons.push(React.createElement(ToggleButton, { variant: "outline-primary", type: "radio", checked: selectedRange == r, onChange: (e) => {
                setSelectedRange(Number(e.currentTarget.value));
            }, key: r, id: `range-${r}`, value: r },
            "\u0420\u0443\u0431\u0456\u0436 ",
            r));
    }
    const defaultMatchCompletion = getMatchCompletion(match, outcomes, {
        range: selectedRange
    });
    const [matchCompletionStatus, setMatchCompletionStatus] = useState(defaultMatchCompletion);
    const getNextDuel = () => {
        var _a;
        for (const duel of (_a = match.duels[selectedRange]) !== null && _a !== void 0 ? _a : []) {
            if (!(duel.id in outcomes.outcomes)) {
                return duel.order;
            }
        }
        return null;
    };
    useEffect(() => {
        setNextDuel(getNextDuel());
        setMatchCompletionStatus(getMatchCompletion(match, outcomes, {
            range: selectedRange
        }));
    }, [outcomes, match, selectedRange]);
    const [nextDuel, setNextDuel] = useState(getNextDuel());
    const [showResults, setShowResults] = useState(false);
    return React.createElement(React.Fragment, null,
        React.createElement(Navbar, { sticky: "top", className: "bg-body-secondary" },
            React.createElement(Container, { fluid: true, className: "justify-content-between" },
                React.createElement(Navbar.Brand, null,
                    "\u041D\u0430\u0441\u0442\u0443\u043F\u043D\u0430 \u0434\u0443\u0435\u043B\u044C: ",
                    React.createElement("b", null, nextDuel)),
                React.createElement(Button, { onClick: () => {
                        setShowResults(true);
                    } },
                    "\u0420\u0435\u0437\u0443\u043B\u044C\u0442\u0430\u0442\u0438 ",
                    React.createElement(ProgressCounter, { status: matchCompletionStatus })))),
        React.createElement(Container, { fluid: true },
            React.createElement(Row, { className: "my-3" },
                React.createElement(Col, null,
                    React.createElement(ButtonGroup, { className: 'd-flex justify-content-between' }, rangeButtons))),
            React.createElement(Row, null,
                React.createElement(Col, null,
                    React.createElement(DuelList, { matchId: params.matchId, range: selectedRange, duels: (_b = match.duels[selectedRange]) !== null && _b !== void 0 ? _b : [], outcomes: outcomes, isStale: outcomesStale, onOutcomeRecorded: handleOutcomeRecorded })))),
        React.createElement(MatchResultsModal, { key: match.id, match: match, outcomes: outcomes, show: showResults, onHide: () => {
                setShowResults(false);
            } }));
}
