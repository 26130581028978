var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useRef } from "react";
import { Button, Form, InputGroup, Modal } from "react-bootstrap";
import { DateTime } from "luxon";
export default function MatchCreateModal(props) {
    const formRef = useRef(null);
    const nameRef = useRef(null);
    const handleSubmit = (event) => __awaiter(this, void 0, void 0, function* () {
        event.preventDefault();
        const formData = new FormData(formRef.current);
        const formDataObj = Object.fromEntries(formData.entries());
        props.onHide(true, { name: formDataObj["name"] });
    });
    const handleGenerateName = () => {
        nameRef.current.value = DateTime.now().toLocaleString(DateTime.DATETIME_MED, { locale: 'uk-UA' });
    };
    return (React.createElement(Modal, { show: props.show, onHide: () => { props.onHide(false); } },
        React.createElement(Form, { ref: formRef, onSubmit: handleSubmit },
            React.createElement(Modal.Header, { closeButton: true },
                React.createElement(Modal.Title, null, "\u0421\u0442\u0432\u043E\u0440\u0438\u0442\u0438 \u043C\u0430\u0442\u0447")),
            React.createElement(Modal.Body, null,
                React.createElement(Form.Group, null,
                    React.createElement(Form.Label, null, "\u041D\u0430\u0437\u0432\u0430 \u043C\u0430\u0442\u0447\u0443"),
                    React.createElement(InputGroup, { className: "mb-3" },
                        React.createElement(Form.Control, { ref: nameRef, name: "name", type: "text", "aria-describedby": "button-generate-name", required: true }),
                        React.createElement(Button, { id: "button-generate-name", variant: "outline-primary", onClick: handleGenerateName }, "\u0417\u0433\u0435\u043D\u0435\u0440\u0443\u0432\u0430\u0442\u0438")))),
            React.createElement(Modal.Footer, { className: "d-flex justify-content-between" },
                React.createElement(Button, { variant: "outline-dark", onClick: () => { props.onHide(false); } }, "\u0417\u0430\u043A\u0440\u0438\u0442\u0438"),
                React.createElement(Button, { type: "submit", variant: "primary" }, "\u0421\u0442\u0432\u043E\u0440\u0438\u0442\u0438")))));
}
