var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useRef, useState } from "react";
import MatchLoader from "./loader";
import MatchSetup from "./match_setup";
import DuelsList from "./duels_list";
import { EmptyMatchSetupRequest } from "../models";
import { API_ROOT, getMatchSetupFromLocalStorage, saveMatchSetupToLocalStorage } from "../storage";
import { Button, Form, Modal, Stack } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import { FaDownload, FaPlus, FaTrash } from "react-icons/fa";
import MatchCreateModal from "./match_create_modal";
import { useNavigate } from "react-router-dom";
import { fetchMatchFromPracticarms, openFile } from "./matches/match_service";
export default function App() {
    const [matchSetup, setMatchSetup] = useState(EmptyMatchSetupRequest());
    const [match, setMatch] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [isMatchLoaderVisible, setMatchLoaderVisible] = useState(false);
    const handleMatchSetup = function (r) {
        setMatchSetup(r);
    };
    const handleResetMatchClick = function () {
        if (!confirm("Це скине поточне налаштування матчу: впевнені?")) {
            return;
        }
        setMatchSetup(EmptyMatchSetupRequest());
    };
    const handleMatchLoaded = function (r) {
        setMatchSetup(r);
        setMatchLoaderVisible(false);
    };
    const handleMatchLoaderCanceled = () => {
        setMatchLoaderVisible(false);
    };
    useEffect(() => {
        const storedMatchSetup = getMatchSetupFromLocalStorage();
        setMatchSetup(storedMatchSetup);
    }, []);
    useEffect(() => {
        (() => __awaiter(this, void 0, void 0, function* () {
            setLoading(true);
            const response = yield fetch(`${API_ROOT}/duels`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(matchSetup),
            });
            const receivedMatch = JSON.parse(yield response.text());
            setLoading(false);
            setMatch(receivedMatch);
        }))();
        saveMatchSetupToLocalStorage(matchSetup);
    }, [matchSetup]);
    function handleExcelDownloadClicked() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield fetch(`${API_ROOT}/duels/excel`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(matchSetup),
            });
            const responseBody = yield response.blob();
            openFile(responseBody, "duels.xlsx");
        });
    }
    function handleCreateMatchClicked() {
        return __awaiter(this, void 0, void 0, function* () {
            setShowMatchCreateModal(true);
        });
    }
    const [showMatchCreateModal, setShowMatchCreateModal] = useState(false);
    const navigate = useNavigate();
    const handleMatchCreateHide = (submitted, result) => __awaiter(this, void 0, void 0, function* () {
        setShowMatchCreateModal(false);
        if (!submitted || result == null) {
            return;
        }
        const requestBody = {
            "name": result.name,
            "duels": match,
        };
        const response = yield fetch(`${API_ROOT}/matches`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(requestBody),
        });
        if (response.status != 201) {
            console.log(response.text());
            return;
        }
        const matchId = JSON.parse(yield response.text());
        navigate(`/matches/${matchId}`);
    });
    const [showMatchDownloadModal, setShowMatchDownloadModal] = useState(false);
    const handleMatchDownloadModalHide = (submitted, url) => __awaiter(this, void 0, void 0, function* () {
        setShowMatchDownloadModal(false);
        if (submitted) {
            const fetchedMatchSetup = yield fetchMatchFromPracticarms(url);
            setMatchSetup(fetchedMatchSetup);
        }
    });
    return (React.createElement(Stack, { gap: 2 },
        React.createElement("h1", null, "\u041F\u043B\u0430\u043D\u0443\u0432\u0430\u043D\u043D\u044F"),
        React.createElement(Container, { fluid: true, className: "d-flex justify-content-between" },
            React.createElement(Button, { variant: "primary", onClick: () => { setShowMatchDownloadModal(true); } }, "\u0421\u043A\u0430\u0447\u0430\u0442\u0438 \u0441\u043F\u0438\u0441\u043E\u043A \u0437 Practicarms"),
            React.createElement(Button, { variant: "secondary", onClick: () => {
                    setMatchLoaderVisible(true);
                } },
                React.createElement(FaPlus, null),
                "\u0414\u043E\u0434\u0430\u0442\u0438 \u0441\u043F\u0438\u0441\u043E\u043A \u0437 Practicarms \u0432\u0440\u0443\u0447\u043D\u0443"),
            React.createElement(Button, { variant: "outline-danger", onClick: handleResetMatchClick },
                React.createElement(FaTrash, null),
                "\u041E\u0447\u0438\u0441\u0442\u0438\u0442\u0438")),
        React.createElement(MatchLoader, { visible: isMatchLoaderVisible, onMatchLoaded: handleMatchLoaded, onCanceled: handleMatchLoaderCanceled }),
        React.createElement(MatchSetup, { onMatchSetup: handleMatchSetup, matchSetup: matchSetup }),
        React.createElement(Container, { className: "my-4" },
            React.createElement("h1", null, "\u0414\u0443\u0435\u043B\u0456"),
            React.createElement(Stack, { direction: "horizontal", gap: 3 },
                React.createElement(Button, { variant: "primary", onClick: handleExcelDownloadClicked },
                    React.createElement(FaDownload, null),
                    " \u0421\u043A\u0430\u0447\u0430\u0442\u0438 \u0432\u0456\u0434\u043E\u043C\u0456\u0441\u0442\u044C"),
                React.createElement(Button, { variant: "primary", onClick: handleCreateMatchClicked },
                    React.createElement(FaPlus, null),
                    " \u0421\u0442\u0432\u043E\u0440\u0438\u0442\u0438 \u043C\u0430\u0442\u0447")),
            React.createElement(Container, { className: "my-4" },
                React.createElement(DuelsList, { match: match, isLoading: isLoading }))),
        React.createElement(MatchCreateModal, { show: showMatchCreateModal, onHide: handleMatchCreateHide }),
        React.createElement(MatchDownloadModal, { show: showMatchDownloadModal, onHide: handleMatchDownloadModalHide })));
}
function MatchDownloadModal(props) {
    const formRef = useRef(null);
    const urlRef = useRef(null);
    const defaultFormState = { url: "" };
    const [formState, setFormState] = useState(defaultFormState);
    const handleSubmit = (event) => __awaiter(this, void 0, void 0, function* () {
        event.preventDefault();
        const formData = new FormData(formRef.current);
        const formDataObj = Object.fromEntries(formData.entries());
        let url = formDataObj["url"];
        if (url == "") {
            url = null;
        }
        props.onHide(true, url);
    });
    return (React.createElement(Modal, { show: props.show, onHide: () => props.onHide(false, null) },
        React.createElement(Form, { ref: formRef, onSubmit: handleSubmit },
            React.createElement(Modal.Header, { closeButton: true }, "\u0421\u043A\u0430\u0447\u0430\u0442\u0438 \u043C\u0430\u0442\u0447"),
            React.createElement(Modal.Body, null,
                React.createElement(Form.Group, null,
                    React.createElement(Form.Label, null, "\u041F\u043E\u0441\u0438\u043B\u0430\u043D\u043D\u044F \u043D\u0430 \u0441\u043F\u0438\u0441\u043E\u043A \u0443\u0447\u0430\u0441\u043D\u0438\u043A\u0456\u0432"),
                    React.createElement(Form.Control, { ref: urlRef, name: "url", placeholder: "https://practicarms.ua/event-5897-participants-duelnii-kubok-bandershtadtu-2024.html" }))),
            React.createElement(Modal.Footer, null,
                React.createElement(Button, { variant: "outline-dark", onClick: () => { props.onHide(false); } }, "\u0417\u0430\u043A\u0440\u0438\u0442\u0438"),
                React.createElement(Button, { type: "submit", variant: "primary" }, "\u0421\u043A\u0430\u0447\u0430\u0442\u0438")))));
}
