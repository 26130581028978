var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from "react";
import { Accordion, AccordionItem, Alert, Badge, Button, Card, Form, ListGroupItem, Modal, Spinner, Stack, ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import { recordOutcome } from "./match_service";
import Container from "react-bootstrap/Container";
import { FaSave } from "react-icons/fa";
import { DateTime } from "luxon";
var ParticipantVictoryState;
(function (ParticipantVictoryState) {
    ParticipantVictoryState[ParticipantVictoryState["NoRecord"] = 0] = "NoRecord";
    ParticipantVictoryState[ParticipantVictoryState["Win"] = 1] = "Win";
    ParticipantVictoryState[ParticipantVictoryState["Loss"] = 2] = "Loss";
    ParticipantVictoryState[ParticipantVictoryState["DQ"] = 3] = "DQ";
    ParticipantVictoryState[ParticipantVictoryState["Reshoot"] = 4] = "Reshoot";
})(ParticipantVictoryState || (ParticipantVictoryState = {}));
function getVictoryState(outcome, win, dq, reshoot) {
    if (!outcome) {
        return ParticipantVictoryState.NoRecord;
    }
    if (reshoot) {
        return ParticipantVictoryState.Reshoot;
    }
    if (dq) {
        return ParticipantVictoryState.DQ;
    }
    if (win) {
        return ParticipantVictoryState.Win;
    }
    else {
        return ParticipantVictoryState.Loss;
    }
}
function getParticipantNameAndBadge(name, vic) {
    let badge = React.createElement(React.Fragment, null);
    let color = "";
    switch (vic) {
        case ParticipantVictoryState.DQ:
            color = 'text-dark fw-light';
            badge = React.createElement(Badge, { bg: "dark" }, "dq");
            break;
        case ParticipantVictoryState.Win:
            color = 'text-success fw-bolder';
            badge = React.createElement(Badge, { bg: "success" }, "win");
            break;
        case ParticipantVictoryState.Loss:
            color = 'text-danger fw-light';
            badge = React.createElement(Badge, { bg: "danger" }, "loss");
            break;
        case ParticipantVictoryState.Reshoot:
            color = 'text-dark fw-light';
            badge = React.createElement(Badge, { bg: "info" }, "reshoot");
        case ParticipantVictoryState.NoRecord:
            color = '';
            break;
    }
    return {
        name: React.createElement(React.Fragment, null, name),
        badge: React.createElement(React.Fragment, null, badge),
        colorClass: color
    };
}
export default function DuelCard(props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    const participantLeft = props.duel.leftName;
    const participantRight = props.duel.rightName;
    const victoryState = {
        left: getVictoryState(props.outcome != undefined, (_b = (_a = props.outcome) === null || _a === void 0 ? void 0 : _a.victory) === null || _b === void 0 ? void 0 : _b.left, (_d = (_c = props.outcome) === null || _c === void 0 ? void 0 : _c.dq) === null || _d === void 0 ? void 0 : _d.left, (_e = props.outcome) === null || _e === void 0 ? void 0 : _e.reshoot),
        right: getVictoryState(props.outcome != undefined, (_g = (_f = props.outcome) === null || _f === void 0 ? void 0 : _f.victory) === null || _g === void 0 ? void 0 : _g.right, (_j = (_h = props.outcome) === null || _h === void 0 ? void 0 : _h.dq) === null || _j === void 0 ? void 0 : _j.right, (_k = props.outcome) === null || _k === void 0 ? void 0 : _k.reshoot),
    };
    const participantSpan = (name, vic) => {
        const nameAndBadge = getParticipantNameAndBadge(name, vic);
        return (React.createElement(Card, { className: "h-100" },
            React.createElement(Card.Body, null,
                React.createElement(Stack, { className: `h5 ${nameAndBadge.colorClass}`, gap: 1 },
                    nameAndBadge.name,
                    nameAndBadge.badge))));
    };
    const duelOutcome = (outcome) => {
        var _a;
        if (outcome == undefined) {
            return React.createElement(React.Fragment, null);
        }
        const outcomeDate = DateTime.fromISO(outcome.created_at, { zone: "utc" }).toLocal();
        const outcomeDateString = (_a = outcomeDate.toLocaleString(DateTime.TIME_24_WITH_SECONDS)) !== null && _a !== void 0 ? _a : '';
        return (React.createElement(React.Fragment, null,
            "\u0417\u0430\u043F\u0438\u0441\u0430\u043D\u043E \u043E ",
            outcomeDateString,
            "."));
    };
    const duelActions = (outcome) => {
        if (props.isStale) {
            return React.createElement(Button, { size: "lg", variant: "primary", disabled: true },
                React.createElement(Spinner, null));
        }
        if (outcome == undefined) {
            return (React.createElement(Button, { size: "lg", variant: 'primary', onClick: () => {
                    setShowModal(true);
                } },
                "\u0421\u0443\u0434\u0438\u0442\u0438 \u0434\u0443\u0435\u043B\u044C \u2116",
                props.duel.order));
        }
        return (React.createElement(Button, { size: "lg", variant: 'outline-secondary', onClick: () => {
                setShowModal(true);
            } },
            "\u041F\u0435\u0440\u0435\u0433\u043B\u044F\u043D\u0443\u0442\u0438 \u0434\u0443\u0435\u043B\u044C \u2116",
            props.duel.order));
    };
    const defaultShowModal = false;
    const [showModal, setShowModal] = useState(defaultShowModal);
    const handleClose = (victory, dq, reshoot) => __awaiter(this, void 0, void 0, function* () {
        if (victory != null || dq != null || reshoot) {
            const newOutcome = {
                duel_id: props.duel.id,
                victory: victory,
                dq: dq,
                reshoot: reshoot,
                dummy: reshoot,
            };
            if (props.outcome == null || (newOutcome.victory != props.outcome.victory ||
                newOutcome.dq != props.outcome.dq ||
                newOutcome.reshoot != props.outcome.reshoot)) {
                yield recordOutcome(props.matchId, newOutcome);
                props.onOutcomeRecorded();
            }
        }
        setShowModal(false);
    });
    return (React.createElement(React.Fragment, null,
        React.createElement(Card, { className: "my-4", bg: "light" },
            React.createElement(Card.Body, { className: 'd-flex justify-content-between' },
                React.createElement(Stack, { gap: 2 },
                    React.createElement(Stack, { direction: "horizontal", className: "d-flex justify-content-between", gap: 2 },
                        participantSpan(participantLeft, victoryState.left),
                        participantSpan(participantRight, victoryState.right)),
                    React.createElement(Card.Title, null, duelOutcome(props.outcome)),
                    duelActions(props.outcome)))),
        React.createElement(OutcomeModal, { onClose: handleClose, show: showModal, order: props.duel.order, leftName: participantLeft, rightName: participantRight, outcome: props.outcome, outcomes: props.outcomes })));
}
var Victory;
(function (Victory) {
    Victory["Left"] = "left";
    Victory["None"] = "none";
    Victory["Right"] = "right";
})(Victory || (Victory = {}));
function OutcomeModal(params) {
    var _a, _b;
    const parseVictory = (outcome) => {
        if ((outcome === null || outcome === void 0 ? void 0 : outcome.victory) == null) {
            return null;
        }
        if (outcome.victory.right) {
            return Victory.Right;
        }
        if (outcome.victory.left) {
            return Victory.Left;
        }
        return Victory.None;
    };
    const [victory, setVictory] = useState(parseVictory(params.outcome));
    const handleVictoryChanged = (val) => {
        setVictory(val);
    };
    const parseDQ = (outcome) => {
        var _a, _b, _c, _d, _e;
        const defaultDQ = [];
        if (!((_a = params.outcome) === null || _a === void 0 ? void 0 : _a.dq)) {
            return [];
        }
        if ((_c = (_b = params.outcome) === null || _b === void 0 ? void 0 : _b.dq) === null || _c === void 0 ? void 0 : _c.left) {
            defaultDQ.push("left");
        }
        if ((_e = (_d = params.outcome) === null || _d === void 0 ? void 0 : _d.dq) === null || _e === void 0 ? void 0 : _e.right) {
            defaultDQ.push("right");
        }
        return defaultDQ;
    };
    const [dq, setDQ] = useState(parseDQ(params.outcome));
    const handleDQChanged = (e) => {
        setDQ(e);
    };
    const [reshoot, setReshoot] = useState((_a = params.outcome) === null || _a === void 0 ? void 0 : _a.reshoot);
    // correctly load the state on startup
    useEffect(() => {
        var _a, _b;
        setReshoot((_b = (_a = params.outcome) === null || _a === void 0 ? void 0 : _a.reshoot) !== null && _b !== void 0 ? _b : false);
        setVictory(parseVictory(params.outcome));
        setDQ(parseDQ(params.outcome));
    }, [params.outcome]);
    const defaultJudgement = {};
    const [judgement, setJudgement] = useState(defaultJudgement);
    useEffect(() => {
        const leftDQ = dq.includes("left");
        const rightDQ = dq.includes("right");
        if (leftDQ && victory == Victory.Left) {
            setVictory(Victory.None);
        }
        if (rightDQ && victory == Victory.Right) {
            setVictory(Victory.None);
        }
        if (leftDQ && rightDQ) {
            setVictory(Victory.None);
        }
    }, [dq]);
    useEffect(() => {
        let newJudgement = {};
        if (reshoot) {
            newJudgement.reshoot = true;
            if (params.show) {
                params.onClose(newJudgement.victory, newJudgement.dq, newJudgement.reshoot);
                return;
            }
        }
        else {
            if (dq.length > 0) {
                newJudgement.dq = { left: dq.includes("left"), right: dq.includes("right") };
            }
            if (victory) {
                newJudgement.victory = { left: false, right: false };
                if (victory == Victory.Left) {
                    newJudgement.victory.left = true;
                }
                if (victory == Victory.Right) {
                    newJudgement.victory.right = true;
                }
                if (params.show) {
                    params.onClose(newJudgement.victory, newJudgement.dq, newJudgement.reshoot);
                    return;
                }
            }
        }
        setJudgement(newJudgement);
        setCanSubmit(newJudgement.reshoot || (newJudgement.victory != null));
    }, [victory, dq, reshoot]);
    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        params.onClose(judgement.victory, judgement.dq, judgement.reshoot);
    };
    const [canSubmit, setCanSubmit] = useState(false);
    const handleHide = () => {
        var _a;
        setReshoot((_a = params.outcome) === null || _a === void 0 ? void 0 : _a.reshoot);
        setDQ([]);
        setVictory(parseVictory(params.outcome));
        params.onClose();
    };
    const defaultAccordionKey = (() => {
        if (dq.length > 0)
            return "dq";
        return null;
    })();
    return (React.createElement(Modal, { show: params.show, onHide: handleHide, animation: false, fullscreen: true },
        React.createElement(Modal.Header, { closeButton: true },
            React.createElement(Modal.Title, null,
                params.order,
                " ",
                React.createElement("b", null, params.leftName),
                " vs ",
                React.createElement("b", null, params.rightName))),
        React.createElement(Modal.Body, null,
            React.createElement(OutcomeRender, { outcome: params.outcome, outcomes: params.outcomes, leftName: params.leftName, rightName: params.rightName })),
        React.createElement(Modal.Footer, { className: "d-flex justify-content-between" },
            React.createElement(Form, { onSubmit: handleSubmit },
                React.createElement(Accordion, { defaultActiveKey: defaultAccordionKey, className: "mb-5" },
                    React.createElement(AccordionItem, { eventKey: "dq" },
                        React.createElement(Accordion.Header, null, "DQ"),
                        React.createElement(Accordion.Body, null,
                            React.createElement(Form.Group, { className: "mb-3" },
                                React.createElement(ToggleButtonGroup, { name: "dq", type: "checkbox", defaultValue: dq, size: "lg", className: 'd-flex justify-content-between', onChange: handleDQChanged },
                                    React.createElement(ToggleButton, { id: "dq-left", value: "left", variant: "outline-danger", disabled: reshoot, checked: dq.includes("left") },
                                        "DQ ",
                                        params.leftName),
                                    React.createElement(ToggleButton, { id: "dq-right", value: "right", variant: "outline-danger", disabled: reshoot, checked: dq.includes("right") },
                                        "DQ ",
                                        params.rightName)))))),
                React.createElement(Form.Group, { className: "mb-5" },
                    React.createElement(ToggleButton, { id: `reshoot-${(_b = params.outcome) === null || _b === void 0 ? void 0 : _b.duel_id}`, size: "lg", value: "reshoot", type: "checkbox", variant: "outline-primary", className: "d-block w-100", checked: reshoot, onChange: (e) => {
                            setReshoot(e.currentTarget.checked);
                        } }, "\u041F\u0435\u0440\u0435\u0441\u0442\u0440\u0456\u043B")),
                React.createElement(Form.Group, { className: "mb-5" },
                    React.createElement(ToggleButtonGroup, { name: "victory", type: "radio", value: victory, size: "lg", onChange: handleVictoryChanged },
                        React.createElement(ToggleButton, { id: "win-left", value: "left", variant: "outline-success", disabled: dq.includes("left") || reshoot, checked: victory == Victory.Left, className: "p-1" },
                            React.createElement("p", null, "\u041F\u0435\u0440\u0435\u043C\u043E\u0433\u0430 \u0437\u043B\u0456\u0432\u0430"),
                            React.createElement("span", { className: "fw-bolder" }, params.leftName)),
                        React.createElement(ToggleButton, { id: "win-none", value: "none", variant: "outline-danger", disabled: reshoot, checked: victory == Victory.None, style: { display: "flex", alignItems: "center" }, className: "p-1" }, "\u0414\u0432\u0456 \u043F\u043E\u0440\u0430\u0437\u043A\u0438"),
                        React.createElement(ToggleButton, { id: "win-right", value: "right", variant: "outline-success", disabled: dq.includes("right") || reshoot, checked: victory == Victory.Right, className: "p-1" },
                            React.createElement("p", null, "\u041F\u0435\u0440\u0435\u043C\u043E\u0433\u0430 \u0441\u043F\u0440\u0430\u0432\u0430"),
                            React.createElement("span", { className: "fw-bolder" }, params.rightName)))),
                React.createElement(Container, { className: "mt-5 d-flex justify-content-between" },
                    React.createElement(Button, { size: "lg", variant: "outline-dark", onClick: handleHide }, "\u0417\u0430\u043A\u0440\u0438\u0442\u0438"),
                    React.createElement(Button, { size: "lg", disabled: !canSubmit, type: "submit" },
                        React.createElement(FaSave, null),
                        " \u0417\u0431\u0435\u0440\u0435\u0433\u0442\u0438"))))));
}
function OutcomeRender(props) {
    var _a, _b;
    if (!props.outcome)
        return React.createElement(React.Fragment, null);
    let victoryText = "";
    let alerts = [];
    if (props.outcome.reshoot) {
        alerts.push(React.createElement(Alert, { key: "reshoot", variant: "info" }, "\u041F\u0435\u0440\u0435\u0441\u0442\u0440\u0456\u043B."));
    }
    if (props.outcome.dummy) {
        alerts.push(React.createElement(Alert, { key: "dummy", variant: "info" }, "\u0424\u0456\u043A\u0442\u0438\u0432\u043D\u0438\u0439 \u0440\u0435\u0437\u0443\u043B\u044C\u0442\u0430\u0442 \u0434\u0443\u0435\u043B\u0456!"));
    }
    if ((_a = props.outcome.dq) === null || _a === void 0 ? void 0 : _a.left) {
        alerts.push(React.createElement(Alert, { key: "dqLeft", variant: "danger" },
            "DQ: ",
            props.leftName));
    }
    if ((_b = props.outcome.dq) === null || _b === void 0 ? void 0 : _b.right) {
        alerts.push(React.createElement(Alert, { key: "dqRight", variant: "danger" },
            "DQ: ",
            props.rightName));
    }
    const createdAt = DateTime.fromISO(props.outcome.created_at);
    const renderOutcomeForHistory = (val) => {
        var _a, _b, _c, _d;
        const createdAt = DateTime.fromISO(val.created_at).toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS, { locale: "uk-UA" });
        const participantLeft = props.leftName;
        const participantRight = props.rightName;
        const victoryState = {
            left: getVictoryState(val != undefined, (_a = val === null || val === void 0 ? void 0 : val.victory) === null || _a === void 0 ? void 0 : _a.left, (_b = val === null || val === void 0 ? void 0 : val.dq) === null || _b === void 0 ? void 0 : _b.left, val === null || val === void 0 ? void 0 : val.reshoot),
            right: getVictoryState(val != undefined, (_c = val === null || val === void 0 ? void 0 : val.victory) === null || _c === void 0 ? void 0 : _c.right, (_d = val === null || val === void 0 ? void 0 : val.dq) === null || _d === void 0 ? void 0 : _d.right, val === null || val === void 0 ? void 0 : val.reshoot),
        };
        const leftNameAndBadge = getParticipantNameAndBadge(participantLeft, victoryState.left);
        const rightNameAndBadge = getParticipantNameAndBadge(participantRight, victoryState.right);
        return (React.createElement(Card, null,
            React.createElement(Card.Header, null,
                React.createElement(Card.Title, null, createdAt)),
            React.createElement(Card.Body, null,
                React.createElement(Stack, { direction: "horizontal", gap: 3 },
                    React.createElement("h5", { className: leftNameAndBadge.colorClass },
                        leftNameAndBadge.badge,
                        " ",
                        leftNameAndBadge.name),
                    React.createElement("h5", { className: rightNameAndBadge.colorClass },
                        rightNameAndBadge.badge,
                        " ",
                        rightNameAndBadge.name)))));
    };
    return (React.createElement(React.Fragment, null,
        alerts,
        React.createElement(Accordion, { defaultActiveKey: null },
            React.createElement(Accordion.Item, { eventKey: "history" },
                React.createElement(Accordion.Header, null,
                    "\u0406\u0441\u0442\u043E\u0440\u0456\u044F \u0440\u0435\u0434\u0430\u0433\u0443\u0432\u0430\u043D\u044C (",
                    props.outcomes.length,
                    ")"),
                React.createElement(Accordion.Body, null,
                    React.createElement(Stack, { gap: 3 }, props.outcomes.map(value => {
                        return (React.createElement(ListGroupItem, { key: value.duel_id }, renderOutcomeForHistory(value)));
                    })))))));
}
