import React from "react";
import { getMostRecentOutcomes } from "./match_service";
import DuelCard from "./duel_card";
export default function DuelList(props) {
    var _a, _b;
    const duels = props.duels;
    duels.sort((a, b) => {
        return a.order - b.order;
    });
    const mostRecentOutcomes = getMostRecentOutcomes(props.outcomes);
    const duelRows = [];
    for (const duel of duels) {
        const outcome = (_a = mostRecentOutcomes[duel.id]) !== null && _a !== void 0 ? _a : undefined;
        const outcomes = (_b = props.outcomes.outcomes[duel.id]) !== null && _b !== void 0 ? _b : undefined;
        duelRows.push(React.createElement(DuelCard, { key: duel.id, matchId: props.matchId, duel: duel, outcome: outcome, outcomes: outcomes, isStale: props.isStale, onOutcomeRecorded: props.onOutcomeRecorded }));
    }
    return (React.createElement(React.Fragment, null, duelRows));
}
