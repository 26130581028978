export const CLASSES = ["S", "SL", "SM", "M", "O"];
export const RANGES = ["1", "2"];
export function EmptyMatchSetupRequest() {
    return {
        ranges: {
            "1": {
                classes: {
                    S: { participants: [], times: 1 },
                    SL: { participants: [], times: 1 },
                    SM: { participants: [], times: 1 },
                    M: { participants: [], times: 1 },
                    O: { participants: [], times: 1 },
                }
            },
            "2": {
                classes: {
                    S: { participants: [], times: 1 },
                    SL: { participants: [], times: 1 },
                    SM: { participants: [], times: 1 },
                    M: { participants: [], times: 1 },
                    O: { participants: [], times: 1 },
                }
            }
        }
    };
}
