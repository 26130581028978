const LOCAL_STORAGE_KEY = "participants-1";
export const API_ROOT = "/api";
export function saveMatchSetupToLocalStorage(participants) {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(participants));
}
export function getMatchSetupFromLocalStorage() {
    const storedParticipants = localStorage.getItem(LOCAL_STORAGE_KEY);
    const participants = JSON.parse(storedParticipants);
    return participants;
}
